import {
  HandleMouseDownProps,
  HandleMouseUpProps,
  HandleMouseUpOutsideProps,
  HandleTouchEndProps,
  HandleTouchStartProps,
  HandleZoomInProps,
  HandleZoomOutProps,
  HandleCoomonMoveProps,
} from 'catalog/types/zoomHandlers'

//
// Zoom Handlers
// ----------------------------------------------------------------------

/**
 * ZoomIn handler for react-pageflip
 *
 * @param setZoom - React.SetStateAction
 * @param zoomInAmout
 */
export const handleZoomIn: HandleZoomInProps = (setZoom, zoomInAmout) => {
  setZoom(zoomInAmout)
}

/**
 * ZoomOut handler for react-pageflip
 *
 * @param setZoom - React.SetStateAction
 * @param setPosition - React.SetStateAction
 * @param zoomAmout - Default zoom
 * @param position - Default book position
 */
export const handleZoomOut: HandleZoomOutProps = (
  setZoom,
  setPosition,
  zoomValue,
  position
) => {
  setZoom(zoomValue)
  setPosition(position)
}

//
// Common Zoom Move Handlers ( Mouse/Touch )
// ----------------------------------------------------------------------

/**
 * Handler for Zoom (Move & Drag)
 *
 * @param e - React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>,
 * @param isDragging
 * @param isZoomedIn
 * @param dragStart
 * @param position - Default book position
 * @param setPosition - React.SetStateAction
 * @param setDragStart - React.SetStateAction
 */
export const commonMoveHandler: HandleCoomonMoveProps = (
  e,
  isDragging,
  isZoomedIn,
  dragStart,
  position,
  setPosition,
  setDragStart
) => {
  if (isDragging && isZoomedIn) {
    const clientX = 'touches' in e ? e.touches[0].clientX : e.clientX
    const clientY = 'touches' in e ? e.touches[0].clientY : e.clientY
    const offsetX = clientX - dragStart.x
    const offsetY = clientY - dragStart.y
    setPosition({
      x: position.x + offsetX,
      y: position.y + offsetY,
    })
    setDragStart({ x: clientX, y: clientY })
  }
}

//
// Mouse Zoom Handlers
// ----------------------------------------------------------------------

export const handleMouseDown: HandleMouseDownProps = (
  e,
  setIsDragging,
  setDragStart
) => {
  setIsDragging(true)
  setDragStart({ x: e.clientX, y: e.clientY })
}

export const handleMouseUp: HandleMouseUpProps = (setIsDragging) => {
  setIsDragging(false)
}

/**
 * Move handler
 *
 * @param e - React.MouseEvent<HTMLDivElement>,
 * @param isDragging
 * @param isZoomedIn
 * @param dragStart
 * @param position - Default book position
 * @param setPosition - React.SetStateAction
 * @param setDragStart - React.SetStateAction
 */
export const handleMouseMove: HandleCoomonMoveProps = (
  e,
  isDragging,
  isZoomedIn,
  dragStart,
  position,
  setPosition,
  setDragStart
) => {
  commonMoveHandler(
    e,
    isDragging,
    isZoomedIn,
    dragStart,
    position,
    setPosition,
    setDragStart
  )
}

export const handleMouseUpOutside: HandleMouseUpOutsideProps = (
  setIsDragging
) => {
  setIsDragging(false)
}

//
// Touch Zoom Handlers
// ----------------------------------------------------------------------
let touchStartX = 0
let touchEndX = 0

/**
 * Handler for Touch start
 *
 * @param e - React.TouchEvent<HTMLDivElement>,
 * @param setIsDragging - React.SetStateAction
 * @param setDragStart - React.SetStateAction
 */
export const handleTouchStart: HandleTouchStartProps = (
  e,
  setIsDragging,
  setDragStart
) => {
  const touch = e.touches[0]
  touchStartX = touch.clientX

  setIsDragging(true)
  setDragStart({ x: touch.clientX, y: touch.clientY })
}

/**
 * Handler for Touch End
 *
 * @param isZoomedIn
 * @param setIsDragging - React.SetStateAction
 * @param handlePrevPageCallback - Prev Page Handler
 * @param handleNextPageCallback - Next Page Handler
 */
export const handleTouchEnd: HandleTouchEndProps = (
  isZoomedIn,
  setIsDragging,
  handlePrevPageCallback,
  handleNextPageCallback
) => {
  if (!isZoomedIn) {
    const touchDiff = touchEndX - touchStartX

    if (touchDiff > 0) {
      handlePrevPageCallback()
    } else if (touchDiff < 0) {
      handleNextPageCallback()
    } else {
      // No swipe / free to do anything else
    }
  }

  setIsDragging(false)
}

/**
 * Drag handler
 *
 * @param e - React.TouchEvent<HTMLDivElement>,
 * @param isDragging
 * @param isZoomedIn
 * @param dragStart
 * @param position - Default book position
 * @param setPosition - React.SetStateAction
 * @param setDragStart - React.SetStateAction
 */
export const handleTouchMove: HandleCoomonMoveProps = (
  e,
  isDragging,
  isZoomedIn,
  dragStart,
  position,
  setPosition,
  setDragStart
) => {
  if ('touches' in e && e.touches && e.touches.length > 0) {
    const touch = e.touches[0]
    touchEndX = touch.clientX
  }

  commonMoveHandler(
    e,
    isDragging,
    isZoomedIn,
    dragStart,
    position,
    setPosition,
    setDragStart
  )
}
