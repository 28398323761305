import React from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'

import { pxToRem } from 'styles/mixins'
import { TextButton } from 'components/Typography'

import { ReactComponent as Rectangle } from 'assets/icons/rectangle.svg'

const TRANSITION = '300ms ease-in-out'

type ButtonVariants = 'primary' | 'outlined' | 'white'

type ButtonProps = {
  label: string
  variant?: ButtonVariants
  disabled?: boolean
  href?: string
  className?: string
  type?: 'button' | 'submit' | 'reset'
  onClick?: () => void
}

const WhiteLine = styled.div<{ variant?: ButtonVariants }>`
  width: 12px;
  height: 1px;
  background-color: #fff;

  transition: width ${TRANSITION};

  ${({ variant }) =>
    variant === 'white' &&
    css`
      background-color: #000;
    `}
`

const WhiteLineLeft = styled(WhiteLine)`
  width: 0px;
`

const WhiteLineRight = styled(WhiteLine)``

const InnerWrapper = styled.div<{ variant: ButtonVariants }>`
  display: flex;
  align-items: center;
  width: auto;
  position: relative;
  height: 44px;
  max-height: 44px;

  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  border: 1px solid transparent;
  padding: 12px 14px;

  font-size: ${pxToRem(16)}rem;
  font-weight: 600;

  z-index: 0;

  transition: background-color ${TRANSITION};

  ${({ variant }) =>
    variant === 'outlined' &&
    css`
      background-color: ${({ theme }) => theme.colors.white};
      border-color: ${({ theme }) => theme.colors.primary};
      border-left: none;
      border-right: none;
    `};

  ${({ variant }) =>
    variant === 'white' &&
    css`
      background-color: ${({ theme }) => theme.colors.white};
      border-color: ${({ theme }) => theme.colors.white};
      border-left: none;
      border-right: none;
    `};
`
const Triangle = styled.div<{ colored: boolean; coloredWhite?: boolean }>`
  position: relative;
  width: 24px;
  height: 24px;
  transform: rotate(67.5deg) skewX(45deg) scaleY(0.7071);

  z-index: -1;

  transition: background-color ${TRANSITION};

  ${({ colored }) =>
    colored &&
    css`
      background-color: ${({ theme }) => theme.colors.primary};
    `}

  ${({ coloredWhite }) =>
    coloredWhite &&
    css`
      background-color: ${({ theme }) => theme.colors.white};
    `}
`

const TriangleLeft = styled(Triangle)`
  border-left: 1px solid ${({ theme }) => theme.colors.primary};
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary};

  position: absolute;
  left: 10px;
  top: 50%;

  transform: translateX(-50%) translateY(-52%) rotate(67.5deg) skewX(45deg)
    scaleY(0.7071);
`

const TriangleRight = styled(Triangle)`
  border-top: 1px solid ${({ theme }) => theme.colors.primary};
  border-right: 1px solid ${({ theme }) => theme.colors.primary};

  position: absolute;
  right: 10px;
  top: 50%;

  transform: translateX(50%) translateY(-52%) rotate(67.5deg) skewX(45deg)
    scaleY(0.7071);
`

const RectangleIcon = styled(Rectangle)``

const RectangleIconRight = styled.div`
  width: 9px;
  height: 9px;
  margin-left: 8px;

  position: relative;

  transition: all ${TRANSITION};

  & > ${RectangleIcon} {
    position: absolute;
    left: 0px;

    transition: all ${TRANSITION};
  }
`

const RectangleIconLeft = styled.div`
  width: 0px;
  height: 9px;
  margin-right: 0px;

  position: relative;

  transition: all ${TRANSITION};

  & > ${RectangleIcon} {
    position: absolute;
    left: -50px;

    transition: all ${TRANSITION};
  }
`

const StyledButton = styled.button<{
  variant?: ButtonVariants
}>`
  cursor: pointer;
  width: fit-content;

  display: flex;
  align-items: center;

  position: relative;

  border: none;
  background-color: transparent;

  isolation: isolate;
  text-decoration: none;
  overflow: hidden;

  padding: 0 10px;

  ${({ variant }) =>
    variant === 'primary'
      ? css`
          &:hover {
            ${WhiteLineLeft} {
              width: 12px;
            }
            ${WhiteLineRight} {
              width: 0px;
            }
            ${InnerWrapper} {
              background-color: #96785e;
            }
            ${Triangle} {
              background-color: #96785e;
            }
          }
        `
      : css`
          &:hover {
            ${RectangleIconLeft} {
              width: 9px;
              margin-right: 8px;

              & > ${RectangleIcon} {
                left: 0px;
              }
            }
            ${RectangleIconRight} {
              width: 0px;
              margin-left: 0px;

              & > ${RectangleIcon} {
                left: 50px;
              }
            }
          }
        `}

  ${({ variant }) =>
    variant === 'white' &&
    css`
      &:hover {
        ${WhiteLineLeft} {
          width: 12px;
        }
        ${WhiteLineRight} {
          width: 0px;
        }
      }
    `}

  &[disabled] {
    opacity: 0.7;
    cursor: not-allowed;
  }
`

const IconWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`

const IconLeft = styled(IconWrapper)`
  margin-right: 8px;
`
const IconRight = styled(IconWrapper)`
  margin-left: 8px;
`

export const Button: React.FC<ButtonProps> = ({
  variant = 'primary',
  onClick,
  label,
  href,
  type,
  className = 'button',
  disabled,
}) => {
  return href ? (
    <StyledButton
      as={Link}
      target="_blank"
      to={href}
      variant={variant}
      className={className}
    >
      <TriangleLeft
        colored={variant === 'primary'}
        coloredWhite={variant === 'white'}
        className={`${className}-triangle-left`}
      />
      <InnerWrapper variant={variant} className={`${className}-inner-wrapper`}>
        <IconLeft>
          {variant === 'outlined' ? (
            <RectangleIconLeft>
              <RectangleIcon />
            </RectangleIconLeft>
          ) : (
            <WhiteLineRight />
          )}
        </IconLeft>

        {label && (
          <TextButton
            as="span"
            color={variant === 'primary' ? 'white' : 'gray100'}
            dangerouslySetInnerHTML={{ __html: label }}
          />
        )}

        <IconRight>
          {variant === 'outlined' ? (
            <RectangleIconRight>
              <RectangleIcon />
            </RectangleIconRight>
          ) : (
            <WhiteLineRight />
          )}
        </IconRight>
      </InnerWrapper>
      <TriangleRight
        colored={variant === 'primary'}
        coloredWhite={variant === 'white'}
        className={`${className}-triangle-right`}
      />
    </StyledButton>
  ) : (
    <StyledButton
      type={type}
      onClick={onClick}
      variant={variant}
      disabled={disabled}
      className={className}
    >
      <TriangleLeft
        colored={variant === 'primary'}
        coloredWhite={variant === 'white'}
        className={`${className}-triangle-left`}
      />

      <InnerWrapper variant={variant} className={`${className}-inner-wrapper`}>
        <IconLeft>
          {variant === 'outlined' ? (
            <RectangleIconLeft>
              <RectangleIcon />
            </RectangleIconLeft>
          ) : (
            <WhiteLineLeft />
          )}
        </IconLeft>

        {label && (
          <TextButton
            as="span"
            color={variant === 'primary' ? 'white' : 'gray100'}
            dangerouslySetInnerHTML={{ __html: label }}
          />
        )}

        <IconRight>
          {variant === 'outlined' ? (
            <RectangleIconRight>
              <RectangleIcon />
            </RectangleIconRight>
          ) : (
            <WhiteLineRight />
          )}
        </IconRight>
      </InnerWrapper>

      <TriangleRight
        colored={variant === 'primary'}
        coloredWhite={variant === 'white'}
        className={`${className}-triangle-right`}
      />
    </StyledButton>
  )
}
