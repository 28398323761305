import styled, { css } from 'styled-components'
import HTMLFlipBook from 'react-pageflip'

import { BookPositions } from 'catalog/types'
import media from 'styles/media'

import { ReactComponent as DecorationTop } from '../assets/icons/clipTL.svg'
import { ReactComponent as DecorationBottom } from '../assets/icons/clipBL.svg'

export { Catalog as default } from './Catalog'

export interface CatalogProps {
  children: React.ReactNode
  width: number
  height: number
  isMobile: boolean
  pageTransform: boolean
  fullscreenRef: React.RefObject<HTMLDivElement>
  isLoading: boolean
}

export type BookWraperProps = {
  zoom: number
  isDragging: boolean
  isZoomedIn: boolean
  width: number
  height: number
}

type StylesHTMLFlipBookProps = {
  bookPositon: BookPositions
}

export const BookWraperWraper = styled.div<{ width?: number }>`
  width: 100%;
  height: fit-content;

  position: relative;
`

export const StyledDecorationTop = styled(DecorationTop)`
  position: absolute;
  top: 0px;
  left: -15px;

  ${media.lg.max} {
    display: none;
  }
`

export const StyledDecorationBottom = styled(DecorationBottom)`
  position: absolute;
  bottom: 0px;
  right: -15px;

  ${media.lg.max} {
    display: none;
  }
`

export const Wraper = styled.div`
  width: 100%;

  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto 0px;
`

export const BookWraper = styled.div<BookWraperProps>`
  width: calc(100% - 42px);
  height: fit-content;

  margin: 10px auto;
  padding: 20px 0px;

  box-sizing: content-box;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  overflow: ${({ isZoomedIn }) => (isZoomedIn ? 'hidden' : 'visible')};
  cursor: ${({ zoom, isDragging }) =>
    zoom > 1 && isDragging ? 'grabbing' : zoom > 1 ? 'grab' : 'default'};
`

export const StylesHTMLFlipBook = styled(HTMLFlipBook)<StylesHTMLFlipBookProps>`
  .markedContent,
  .react-pdf__Page__annotations,
  .react-pdf__Page__textContent {
    display: none;
  }

  .stf__item:nth-child(odd) .test1 {
    &:after {
      content: '';
      width: 20px;
      height: 100%;

      position: absolute;
      left: 0px;

      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.55) 0,
        rgba(0, 0, 0, 0.3) 25%,
        transparent
      );
    }
  }

  .stf__item:nth-child(even) .test1 {
    &:after {
      content: '';
      width: 20px;
      height: 100%;

      position: absolute;
      right: 0px;

      background: linear-gradient(
        270deg,
        rgba(0, 0, 0, 0.55) 0,
        rgba(0, 0, 0, 0.3) 25%,
        transparent
      );
    }
  }

  .stf__block {
    right: 0;

    ${({ bookPositon }) => {
      if (bookPositon === 'centeredLeft') {
        return css`
          right: -25%;
        `
      } else if (bookPositon === 'centeredRight') {
        return css`
          right: 25%;
        `
      }
    }};

    ${media.lg.max} {
      right: 0;
    }

    transition: right 1.5s ease;
  }
`

export const Placeholder = styled.div<{ width: number; height: number }>`
  width: 100%;

  display: flex;
  justify-content: center;

  aspect-ratio: ${({ width, height }) => (2 * width) / height};

  ${media.lg.max} {
    aspect-ratio: ${({ width, height }) => width / height};
  }
`

export const Box = styled.div`
  height: 100%;

  aspect-ratio: 1/1;

  background-color: white;
`

export const ArrowButton = styled.div`
  cursor: pointer;
  width: 41px;
  height: 64px;

  z-index: 10;

  position: absolute;

  ${media.lg.max} {
    display: none;
  }
`

export const ArrowLeft = styled(ArrowButton)`
  left: -65px;

  ${media.sm.max} {
    left: -32px;
  }
`

export const ArrowRigth = styled(ArrowButton)`
  right: -65px;

  ${media.sm.max} {
    right: -32px;
  }
`
