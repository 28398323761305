import styled, { css } from 'styled-components'
import media from 'styles/media'

import { ReactComponent as Rectangle } from '../../../assets/icons/rectangle.svg'

export * from './Options'

export interface OptionsProps {
  currentPage: number
  pageCount: number
  isMobile: boolean
  isZoomedIn: boolean
  handleSidemenu: () => void
  handleZoomIn: () => void
  handleZoomOut: () => void
  handleFullscreen: () => void
  handlePrevPage: () => void
  handleNextPage: () => void
}

export const PageCount = styled.p`
  min-width: fit-content;

  color: #aa8c72;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.2px;
`

export const OptionsWrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 16px;

  padding: 10px 0px 0px;
  justify-content: center;

  ${media.lg.max} {
    padding: 0px 10px;
    position: absolute;
    bottom: 0px;
    transform: translateY(50%);
  }
  ${media.sm.max} {
    gap: 5px;
  }
`
export const ArrowButton = styled.button`
  cursor: pointer;
  width: 32px;
  height: 48px;

  padding: 0px;

  display: none;
  justify-content: center;

  background-color: transparent;
  border: none;

  ${media.lg.max} {
    display: flex;
  }
`

export const Button = styled.button<{ marginleft?: boolean }>`
  cursor: pointer;
  width: 40px;
  height: 40px;

  padding: 0px;

  display: flex;
  justify-content: center;

  background-color: transparent;
  border: none;

  & svg {
    width: 40px;
    height: 40px;
  }

  ${({ marginleft }) =>
    marginleft &&
    css`
      margin-left: auto;
    `}

  ${media.lg.max} {
    width: 32px;
    height: 32px;
    & svg {
      width: 32px;
      height: 32px;
    }
  }
`

export const Menu = styled.div`
  width: 100%;
  max-width: 345px;
  max-height: 64px;

  padding: 12px 35px;

  position: relative;

  isolation: isolate;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  ${media.lg.max} {
    padding: 19px 32px;

    gap: 61px;
  }
`

export const StyledRectangle = styled(Rectangle)`
  width: 100%;
  height: 100%;

  position: absolute;
  left: 0px;

  z-index: -1;

  object-fit: contain;
`

export const MobileColection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
`
export const MobileWraper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
