import React from 'react'

import { ReactComponent as Logo } from '../../assets/icons/logo.svg'

import { Button } from 'components/Button'

import * as S from '.'

const Heading = () => {
  return (
    <>
      <S.Container>
        <S.TextContainer>
          <S.Text1>Nowy wymiar luksusu</S.Text1>
          <S.Text2>Apartamenty na styku Nowego i Starego Miasta.</S.Text2>
        </S.TextContainer>

        <S.StyledLogo />

        <S.ButtonWraper>
          <Button
            href="https://konwiktorska.pl/"
            label="Zapytaj o apartament"
          />
        </S.ButtonWraper>
      </S.Container>

      <S.MobileContainer>
        <S.StyledLogo />
      </S.MobileContainer>
    </>
  )
}

export default Heading
