import {
  HandleNextPageProps,
  HandlePageJumpProps,
  HandlePrevPageProps,
} from 'catalog/types/pageHandelers'

/**
 *  react-pageflip handler for fliping page to prevous
 *
 * @param pageToJump - the page we will jump to
 * @param pageCount - count of all the pages that are in book
 * @param setBookPositon - React.SetStateAction for centering book
 * @param setCurrentPage - React.SetStateAction for current page
 * @param book - HTMLFlipBook ref
 */
export const handlePageJump: HandlePageJumpProps = (
  pageToJump,
  pageCount,
  setBookPositon,
  setCurrentPage,
  book
) => {
  console.log(pageToJump)
  if (book.current) {
    book.current.pageFlip().turnToPage(pageToJump)

    if (pageToJump === 0) {
      setBookPositon('centeredRight')
      setCurrentPage(1)
    } else if (pageToJump === pageCount - 1) {
      setBookPositon('centeredLeft')
      setCurrentPage(pageCount)
    } else {
      setBookPositon('centered')
      setCurrentPage(pageToJump)
    }
  }
}

/**
 *  react-pageflip handler for prev page flip
 *
 * @param currentPage
 * @param setBookPositon - React.SetStateAction for centering book
 * @param setCurrentPage - React.SetStateAction for current page
 * @param book - HTMLFlipBook ref
 */
export const handlePrevPage: HandlePrevPageProps = (
  currentPage,
  pageCount,
  setBookPositon,
  setCurrentPage,
  isMobile,
  book
) => {
  console.log(currentPage, pageCount, currentPage === 1)

  const nextPageNumber = isMobile ? currentPage - 1 : currentPage - 2

  if (currentPage === 1) {
    handlePageJump(
      pageCount - 1,
      pageCount,
      setBookPositon,
      setCurrentPage,
      book
    )
    return
  }

  if (book.current) {
    book.current.pageFlip().flipPrev('top')

    if (nextPageNumber === 1) {
      setBookPositon('centeredRight')
    } else {
      setBookPositon('centered')
    }

    if (isMobile) {
      setCurrentPage((prev) => prev - 1)
    } else {
      setCurrentPage((prev) => prev - 2)
    }
  }
}

/**
 *  react-pageflip handler for next page flip
 *
 * @param currentPage
 * @param pageCount - count of all the pages that are in book
 * @param setBookPositon - React.SetStateAction for centering book
 * @param setCurrentPage - React.SetStateAction for current page
 * @param book - HTMLFlipBook ref
 */
export const handleNextPage: HandleNextPageProps = (
  currentPage,
  pageCount,
  setBookPositon,
  setCurrentPage,
  isMobile,
  book
) => {
  const nextPageNumber = isMobile ? currentPage + 1 : currentPage + 2

  console.log(
    nextPageNumber,
    currentPage,
    pageCount,
    nextPageNumber >= pageCount
  )

  if (currentPage === pageCount) {
    handlePageJump(0, pageCount, setBookPositon, setCurrentPage, book)
    return
  }

  if (book.current) {
    book.current.pageFlip().flipNext('top')

    if (nextPageNumber >= pageCount) {
      setBookPositon('centeredLeft')
    } else {
      setBookPositon('centered')
    }

    if (isMobile) {
      setCurrentPage((prev) => prev + 1)
    } else {
      if (nextPageNumber >= pageCount) {
        setCurrentPage(pageCount)
        return
      }
      setCurrentPage((prev) => prev + 2)
    }
  }
}
