import React from 'react'
import { graphql } from 'gatsby'
import HomePage, { Data } from '../components/HomePage'
import GlobalStyles from 'styles/global'
import { Seo } from 'components/Seo'

interface HomeProps {
  data: Data
}

const Home: React.FC<HomeProps> = ({ data }) => {
  return (
    <>
      <Seo
        title="Nowy wymiar współczesnego luksusu - Konwiktorska Residence"
        description="Zapoznaj się z katalogiem dotyczącym luksusowej inwestycji na skraju Starego i Nowego Miasta w Warszawie."
      />
      <GlobalStyles />
      <HomePage data={data} />
    </>
  )
}

export default Home

export const query = graphql`
  query HomePage {
    allFile(
      filter: {
        sourceInstanceName: { eq: "assets" }
        relativeDirectory: { eq: "pdfImages/book1" }
      }
    ) {
      nodes {
        relativePath
        name
        extension
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    mobileBackground: file(
      sourceInstanceName: { eq: "assets" }
      relativePath: { eq: "images/mobileBackground.webp" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`
