import React from 'react'
import * as S from '.'

const MobileDots: React.FC<S.MobileDotsProps> = ({
  currentPage,
  pageCount,
}) => {
  const dotConfigs = []

  for (let i = 1; i <= 5; i++) {
    const size = 0
    let selected = false

    const sizeInBounds = 3 >= currentPage || currentPage > pageCount - 2

    if (sizeInBounds) {
      selected =
        i === currentPage ||
        (i !== 3 && i - 3 + currentPage === pageCount) ||
        (i === 5 && currentPage === pageCount)
    } else {
      selected = i === 3
    }

    dotConfigs.push({ size, selected })
  }

  const selectedIndex = dotConfigs.findIndex((item) => item.selected)

  if (selectedIndex === 0) {
    dotConfigs[3].size = 1
    dotConfigs[4].size = 2
  } else if (selectedIndex === 1) {
    dotConfigs[0].size = 1
    dotConfigs[3].size = 1
    dotConfigs[4].size = 2
  } else if (selectedIndex === 2) {
    dotConfigs[0].size = 2
    dotConfigs[1].size = 1
    dotConfigs[3].size = 1
    dotConfigs[4].size = 2
  } else if (selectedIndex === 3) {
    dotConfigs[0].size = 2
    dotConfigs[1].size = 1
    dotConfigs[4].size = 1
  } else if (selectedIndex === 4) {
    dotConfigs[0].size = 2
    dotConfigs[1].size = 1
  }

  return (
    <S.MobileDotsContainer>
      {dotConfigs.map((config, index) => (
        <S.Dot key={index} size={config.size} selected={config.selected} />
      ))}
    </S.MobileDotsContainer>
  )
}

export default MobileDots
