import React, { useCallback, useEffect, useRef, useState } from 'react'

import {
  handleNextPage,
  handlePageJump,
  handlePrevPage,
} from './utils/pageHandlers'
import {
  handleMouseDown,
  handleMouseMove,
  handleMouseUp,
  handleMouseUpOutside,
  handleTouchEnd,
  handleTouchMove,
  handleTouchStart,
  handleZoomIn,
  handleZoomOut,
} from './utils/zoomHandlers'

import { ReactComponent as ArrowLeftIcon } from '../assets/icons/bigArrowLeft.svg'
import { ReactComponent as ArrowRightIcon } from '../assets/icons/bigArrowRight.svg'

import { Options } from './components/Options'
import { Sidemenu } from './components/SideMenu'
import MobileDots from './components/MobileDots/MobileDots'

import { BookPositions, BookRefMethods, Position } from './types'

import * as S from '.'

const ZOOMED_IN_VALUE = 1.5
const DEFAULT_ZOOM = 1

const DEFAULT_BOOK_POSITION = { x: 0, y: 0 }

export const Catalog: React.FC<S.CatalogProps> = ({
  children,
  width,
  height,
  isMobile,
  pageTransform,
  fullscreenRef,
  isLoading,
}) => {
  const [showSidemenu, setShowSidemenu] = useState(false)
  const book = useRef<BookRefMethods>(null)

  const [pageCount, setPageCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)

  const [zoom, setZoom] = useState(DEFAULT_ZOOM)
  const [position, setPosition] = useState<Position>(DEFAULT_BOOK_POSITION)
  const [isDragging, setIsDragging] = useState(false)
  const [dragStart, setDragStart] = useState<Position>(DEFAULT_BOOK_POSITION)

  const [bookPositon, setBookPositon] = useState<BookPositions>('centeredRight')

  // TODO: move to styled-components
  const bookStyle = {
    transform: `scale(${zoom}) translate(${position.x}px, ${position.y}px)`,
  }

  //
  // Callbacks
  // ----------------------------------------------------------------------
  const handlePrevPageCallback = () => {
    handlePrevPage(
      currentPage,
      pageCount,
      setBookPositon,
      setCurrentPage,
      isMobile,
      book
    )
  }

  const handleNextPageCallback = () => {
    handleNextPage(
      currentPage,
      pageCount,
      setBookPositon,
      setCurrentPage,
      isMobile,
      book
    )
  }

  const handlePageJumpCallback = (page: number) => {
    handlePageJump(page, pageCount, setBookPositon, setCurrentPage, book)
  }

  const handleZoomInCallback = () => {
    handleZoomIn(setZoom, ZOOMED_IN_VALUE)
  }

  const handleZoomOutCallback = () => {
    handleZoomOut(setZoom, setPosition, DEFAULT_ZOOM, DEFAULT_BOOK_POSITION)
  }

  const handleTouchEndCallback = () => {
    handleTouchEnd(
      zoom === ZOOMED_IN_VALUE,
      setIsDragging,
      handlePrevPageCallback,
      handleNextPageCallback
    )
  }

  const handleMouseMoveCallback = (e: React.MouseEvent<HTMLDivElement>) => {
    handleMouseMove(
      e,
      isDragging,
      zoom === ZOOMED_IN_VALUE,
      dragStart,
      position,
      setPosition,
      setDragStart
    )
  }

  const handleTouchMovedCallback = (e: React.TouchEvent<HTMLDivElement>) => {
    handleTouchMove(
      e,
      isDragging,
      zoom === ZOOMED_IN_VALUE,
      dragStart,
      position,
      setPosition,
      setDragStart
    )
  }

  //
  // Handlers
  // ----------------------------------------------------------------------
  const handleSidemenu = () => {
    setShowSidemenu((prev) => !prev)
  }

  const handleFullscreen = () => {
    const bookFullscreenRef = fullscreenRef.current

    if (bookFullscreenRef) {
      if (document.fullscreenElement) {
        document
          .exitFullscreen()
          .catch((error) => console.error('Error exiting fullscreen:', error))
      } else {
        bookFullscreenRef
          .requestFullscreen()
          .catch((error) => console.error('Error entering fullscreen:', error))
      }
    }
  }

  //
  // Listeners
  // ----------------------------------------------------------------------
  const handleKeyDown = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'ArrowLeft':
        handlePrevPage(
          currentPage,
          pageCount,
          setBookPositon,
          setCurrentPage,
          isMobile,
          book
        )
        break
      case 'ArrowRight':
        handleNextPageCallback()
        break
      default:
        break
    }
  }

  useEffect(() => {
    window.addEventListener('mouseup', () =>
      handleMouseUpOutside(setIsDragging)
    )
    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('mouseup', () =>
        handleMouseUpOutside(setIsDragging)
      )
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown])

  //
  // Other
  // ----------------------------------------------------------------------

  const onInit = useCallback(() => {
    if (book.current && book.current.pageFlip()) {
      setPageCount(Math.floor(book.current.pageFlip().getPageCount()))
      setCurrentPage(
        Math.floor(book.current.pageFlip().getCurrentPageIndex() + 1)
      )
    }
  }, [pageTransform])

  //
  // TODO: change the way Catalog is updated (remove rand state)
  const [minWidthBreakpoint, setMinWidthBreakpoint] = useState(
    isMobile ? (pageTransform ? 320 : 440) : 395
  )

  useEffect(() => {
    setMinWidthBreakpoint(isMobile ? (pageTransform ? 320 : 400) : 395)

    forceUpdate()
  }, [isMobile, pageTransform])

  const [rand, setRand] = useState<number>(0)
  const forceUpdate = () => {
    setRand(Math.random())
  }
  console.log(isMobile, pageTransform)

  return (
    <S.Wraper>
      {showSidemenu && (
        <Sidemenu
          isZoomedIn={zoom === ZOOMED_IN_VALUE}
          handlePageJump={(page: number) => handlePageJumpCallback(page)}
          handleSidemenu={() => handleSidemenu()}
        />
      )}

      <S.ArrowLeft onClick={handlePrevPageCallback}>
        <ArrowLeftIcon />
      </S.ArrowLeft>

      <S.BookWraperWraper>
        <S.StyledDecorationTop />
        <S.BookWraper
          width={width}
          height={height}
          isZoomedIn={zoom === ZOOMED_IN_VALUE}
          zoom={zoom}
          key={`${rand}`}
          isDragging={isDragging}
          onMouseDown={(e) => handleMouseDown(e, setIsDragging, setDragStart)}
          onTouchStart={(e) => handleTouchStart(e, setIsDragging, setDragStart)}
          onMouseUp={() => handleMouseUp(setIsDragging)}
          onTouchEnd={handleTouchEndCallback}
          onMouseMove={(e) => handleMouseMoveCallback(e)}
          onTouchMove={(e) => handleTouchMovedCallback(e)}
        >
          <S.StylesHTMLFlipBook
            width={width}
            height={height}
            size="stretch"
            minWidth={minWidthBreakpoint}
            maxWidth={1000}
            maxShadowOpacity={0.3}
            ref={book}
            startPage={0}
            onInit={onInit}
            showPageCorners={false}
            style={bookStyle}
            useMouseEvents={false}
            mobileScrollSupport={true}
            bookPositon={bookPositon}
            usePortrait={isMobile}
            showCover={true}
          >
            {React.Children.map(children, (child) => {
              if (React.isValidElement(child)) {
                // @ts-ignore
                return React.cloneElement(child, { currentPage: currentPage })
              }
              return child
            })}
          </S.StylesHTMLFlipBook>
        </S.BookWraper>
        <S.StyledDecorationBottom />
      </S.BookWraperWraper>

      <S.ArrowRigth onClick={handleNextPageCallback}>
        <ArrowRightIcon />
      </S.ArrowRigth>

      <MobileDots currentPage={currentPage} pageCount={pageCount} />

      <Options
        isMobile={isMobile}
        isZoomedIn={zoom === ZOOMED_IN_VALUE}
        currentPage={currentPage}
        pageCount={pageCount}
        handleSidemenu={handleSidemenu}
        handleZoomIn={handleZoomInCallback}
        handleZoomOut={handleZoomOutCallback}
        handleFullscreen={handleFullscreen}
        handlePrevPage={handlePrevPageCallback}
        handleNextPage={handleNextPageCallback}
      />
    </S.Wraper>
  )
}
