import styled, { css } from 'styled-components'
import media from 'styles/media'

export * from './MobileDots'

export type MobileDotsProps = {
  currentPage: number
  pageCount: number
}

export const Dot = styled.div<{ size?: number; selected?: boolean }>`
  content: '';
  width: 12px;
  height: 12px;

  position: relative;

  background-color: white;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  ${({ selected }) =>
    selected &&
    css`
      background-color: #aa8c72;
      border: 2px solid white;
      border-radius: 50%;
    `}

  ${({ size }) =>
    size === 1
      ? css`
          width: 8px;
          height: 8px;
        `
      : size === 2 &&
        css`
          width: 6px;
          height: 6px;
        `}
`

export const MobileDotsContainer = styled.div`
  display: none;

  ${media.lg.max} {
    margin-top: 12px;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
`
