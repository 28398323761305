import { useState, useEffect, useMemo } from 'react'

import { Breakpoints as BreakpointsType } from 'types/theme'
import { breakpoints as themeBreakpoints } from 'styles/theme'
import { keys } from 'utils/object'

type BreakpointsBoolean = {
  [key in keyof BreakpointsType]: boolean
}

const getInitialBreakpoints = (
  defaultBreakpoint: keyof BreakpointsType
): BreakpointsBoolean => {
  return keys(themeBreakpoints).reduce((acc, key) => {
    acc[key] = themeBreakpoints[key] <= themeBreakpoints[defaultBreakpoint]
    return acc
  }, {} as BreakpointsBoolean)
}

const getBrowserBreakpoints = (): BreakpointsBoolean => {
  return keys(themeBreakpoints).reduce((acc, key) => {
    const query = `(min-width: ${themeBreakpoints[key]}px)`
    const { matches } = window.matchMedia(query)
    acc[key] = matches
    return acc
  }, {} as BreakpointsBoolean)
}

const useBreakpoint = (defaultBreakpoint: keyof BreakpointsType = 'base') => {
  const [breakpoints, setBreakpoints] = useState<BreakpointsBoolean>(
    getInitialBreakpoints(defaultBreakpoint)
  )
  const [isLoading, setIsLoading] = useState(true)

  const activeBreakpoint = useMemo(() => {
    const newarray = Object.keys(breakpoints).slice().reverse() as Array<
      keyof BreakpointsBoolean
    >
    return newarray.find((key) => breakpoints[key]) || defaultBreakpoint
  }, [breakpoints, defaultBreakpoint])

  useEffect(() => {
    setIsLoading(false)
    const listener = () => setBreakpoints(getBrowserBreakpoints())
    listener()

    window.addEventListener('resize', listener)
    return () => {
      window?.removeEventListener('resize', listener)
    }
  }, [])

  return { ...breakpoints, isLoading, activeBreakpoint }
}

export default useBreakpoint
