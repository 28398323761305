import styled, { CSSProperties, css } from 'styled-components'
import media from 'styles/media'
import { pxToRem } from 'styles/mixins'
import { Colors } from 'types/theme'

import { Link as GatsbyLink } from 'gatsby'

const TRANSITION = '0.3s ease-in-out'

type TextProps = {
  align?: CSSProperties['textAlign']
  fontSize?: number
  lineHeight?: string
  textTransform?: CSSProperties['textTransform']
  letterSpacing?: string
  fontWeight?: number
  fontFamily?: 'IvyMode' | 'Inter'
  color?: keyof Colors
}

type HeadingProps = TextProps & {
  as?: keyof JSX.IntrinsicElements
}

export const Text = styled.p<TextProps>`
  font-family: ${({ fontFamily }) => fontFamily ?? 'IvyMode'};
  font-weight: ${({ fontWeight }) => fontWeight || 500};
  font-size: ${({ fontSize }) =>
    fontSize ? `${pxToRem(fontSize)}rem` : `${pxToRem(16)}rem`};
  text-align: ${({ align }) => align ?? 'left'};
  color: ${({ color, theme }) =>
    color ? theme.colors[color] : theme.colors.textPrimary};
  line-height: ${({ lineHeight }) => lineHeight ?? '160%'};
  letter-spacing: ${({ letterSpacing }) => letterSpacing ?? 'initial'};
  text-transform: ${({ textTransform }) => textTransform ?? 'initial'};
`

export const Link = styled(GatsbyLink)<{ fillWidth?: boolean }>`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.textPrimary};

  ${({ fillWidth }) =>
    fillWidth &&
    css`
      width: 100%;
    `}
`

export const TextButton = styled(Text)<HeadingProps>`
  font-family: 'IvyMode';
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  letter-spacing: 1px;
  width: max-content;

  text-transform: uppercase;
`

export const TextMenu = styled(Text)`
  font-family: 'IvyMode';
  font-size: 14px;
  line-height: 100%;
  font-weight: 400;
  letter-spacing: 1px;

  text-transform: uppercase;
  text-decoration: none;
  transition: color ${TRANSITION};

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`

export const TextConsent = styled(Text)<HeadingProps>`
  font-family: 'Inter';
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  letter-spacing: 0.2px;
`

export const TextBigLight = styled(Text)<HeadingProps>`
  font-family: 'IvyMode';
  font-size: 28px;
  line-height: 150%;
  font-weight: 300;

  ${media.xl.max} {
    font-size: 26px;
    line-height: 36px;
    letter-spacing: 1px;
  }
`

export const H1Thin = styled(Text)<HeadingProps>`
  font-family: 'IvyMode';
  font-size: 80px;
  line-height: 110%;
  font-weight: 200;
  letter-spacing: 2%;

  //TODO: get mobile styles from figma when ready
  ${media.xl.max} {
    font-size: 40px;
  }
`

export const H1Light = styled(H1Thin)<HeadingProps>`
  font-weight: 300;
`

export const H2Thin = styled(Text)<HeadingProps>`
  font-family: 'IvyMode';
  font-size: 72px;
  line-height: 100%;
  font-weight: 200;

  ${media.xl.max} {
    font-size: 34px;
    line-height: 40px;
    letter-spacing: 1px;
  }
`

export const H2Light = styled(H2Thin)<HeadingProps>`
  font-weight: 300;
`

export const H3Thin = styled(Text)<HeadingProps>`
  font-family: 'IvyMode';
  font-size: 56px;
  line-height: 110%;
  letter-spacing: 2px;
  font-weight: 200;

  ${media.xl.max} {
    font-size: 34px;
    line-height: 40px;
    letter-spacing: 1px;
  }
`

export const H3Light = styled(H3Thin)<HeadingProps>`
  font-weight: 300;
`

export const H4Regular = styled(Text)<HeadingProps>`
  font-family: 'IvyMode';
  font-size: 40px;
  line-height: 60px;
  font-weight: 400;

  //TODO: get mobile styles from figma when ready
  ${media.xl.max} {
    font-size: 28px;
  }
`

export const H5Thin = styled(Text)<HeadingProps>`
  font-family: 'IvyMode';
  font-size: 26px;
  line-height: 36px;
  font-weight: 200;
  letter-spacing: 1px;

  ${media.xl.max} {
    font-size: 24px;
  }
`

export const H5Light = styled(H5Thin)<HeadingProps>`
  font-weight: 300;
`

export const BodyLarge = styled(Text)<HeadingProps>`
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0.2px;
`
