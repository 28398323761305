import styled from 'styled-components'
import media from 'styles/media'

export * from './SideMenu'

export interface SidemenuProps {
  isZoomedIn: boolean
  handlePageJump: (page: number) => void
  handleSidemenu: () => void
}

export interface ImageData {
  name: string
  publicURL: string
}

export interface SidemenuData {
  allFile: {
    nodes: ImageData[]
  }
}

export const CardButton = styled.button`
  cursor: pointer;
`

export const CloseMenu = styled.div`
  width: 100%;

  user-select: none;
`

export const CloseText = styled.button`
  cursor: pointer;
  width: fit-content;
  padding: 3px 10px !important;

  display: flex;
  justify-content: center;
  align-items: center;
`

export const SideMenuContainer = styled.div`
  content: '';
  width: 150px;
  height: 100vh;
  padding: 20px 10px;

  display: flex;
  flex-direction: column;
  gap: 15px;

  overflow-y: scroll;

  position: fixed;
  left: 0px;
  top: 0px;

  background-color: white;

  z-index: 2;

  & button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
  }

  & img {
    margin: 0px;
  }

  ${media.lg.max} {
    display: none;
  }
`
