import React, { useRef } from 'react'

import useBreakpoint from 'hooks/useBreakpoint'

import Heading from 'components/Heading/Heading'
import { Button } from 'components/Button'

import Catalog from '../../catalog'
import * as S from '.'

const Page = React.forwardRef<HTMLDivElement, S.PageProps>(
  (
    { data: { image }, currentPage = 0, pagenumber, pagewidth, pageheight },
    ref
  ) => {
    const page = (currentPage - 1) * 2

    return (
      <div ref={ref}>
        <S.PageContainer pagewidth={pagewidth} pageheight={pageheight}>
          {page + 5 >= pagenumber && <S.Img src={image.src} alt={image.alt} />}
        </S.PageContainer>
      </div>
    )
  }
)

export const HomePage: React.FC<{ data: S.Data }> = ({ data }) => {
  const { lg, sm, isLoading, activeBreakpoint } = useBreakpoint()
  const windowRef = useRef<HTMLDivElement>(null)

  const width = 648
  const height = 648

  const images = data.allFile.nodes
    .map((node) => ({
      image: {
        src: node.childImageSharp?.gatsbyImageData!,
        alt: node.name,
      },
    }))
    .slice()
    .sort((a, b) => {
      const altA = parseInt(a.image.alt.slice(6))
      const altB = parseInt(b.image.alt.slice(6))

      return altA - altB
    })

  return (
    <S.Wraper ref={windowRef}>
      <S.StyledBackground />
      <Heading />

      <S.CatalogWraper>
        <S.StyledMobileBackground
          src={data.mobileBackground.childImageSharp.gatsbyImageData}
          alt=""
          loading="eager"
        />
        <Catalog
          width={width}
          height={height}
          pageTransform={!sm}
          isMobile={!lg}
          fullscreenRef={windowRef}
          isLoading={isLoading}
        >
          {images.map((item, index) => (
            <Page
              key={index}
              pagenumber={index}
              data={item}
              pagewidth={width}
              pageheight={height}
              ref={null}
            />
          ))}
        </Catalog>
      </S.CatalogWraper>

      <S.MobileWraper>
        <S.MobileContainer>
          <S.Text1>Nowy wymiar luksusu</S.Text1>
          <S.Text2>Apartamenty na styku Nowego i Starego Miasta.</S.Text2>
          <Button
            href="https://konwiktorska.pl/"
            label="Zapytaj o apartament"
          />
        </S.MobileContainer>
        <S.Footer>Copyright by Mostostal 2023</S.Footer>
      </S.MobileWraper>
    </S.Wraper>
  )
}
