import React from 'react'

import * as S from '.'

import { ReactComponent as SmallArrowLeft } from '../../../assets/icons/smallArrowLeft.svg'
import { ReactComponent as Fullscreen } from '../../../assets/icons/fullscreen.svg'
import { ReactComponent as Download } from '../../../assets/icons/download.svg'
import { ReactComponent as SideMenu } from '../../../assets/icons/sideMenu.svg'
import { ReactComponent as ZoomOut } from '../../../assets/icons/zoomOut.svg'
import { ReactComponent as ZoomIn } from '../../../assets/icons/zoomIn.svg'
import { ReactComponent as SmallArrowRight } from '../../../assets/icons/smallArrowRight.svg'

export const Options = ({
  currentPage,
  pageCount,
  isMobile,
  isZoomedIn,
  handleSidemenu,
  handleZoomIn,
  handleZoomOut,
  handleFullscreen,
  handlePrevPage,
  handleNextPage,
}: S.OptionsProps) => {
  return (
    <S.OptionsWrapper>
      <S.ArrowButton onClick={handlePrevPage} disabled={isZoomedIn}>
        <SmallArrowLeft />
      </S.ArrowButton>

      <S.Menu>
        <S.StyledRectangle />
        {!isMobile && (
          <>
            <S.PageCount>
              {currentPage} / {pageCount}
            </S.PageCount>
            <S.Button
              onClick={handleFullscreen}
              aria-labelledby="Book go Fullscreen"
              marginleft
            >
              <Fullscreen />
            </S.Button>

            <S.Button
              as="a"
              href="konwiktorska_book.pdf"
              download
              aria-labelledby="Book Download PDF File"
            >
              <Download />
            </S.Button>
            {/* <S.Button onClick={handleSidemenu}>
              <SideMenu />
            </S.Button> */}
            {/* <S.Button
              onClick={handleZoomOut}
              marginleft
              aria-labelledby="Book Zoom Out Button"
            >
              <ZoomOut />
            </S.Button>
            <S.Button
              onClick={handleZoomIn}
              aria-labelledby="Book Zoom In Button"
            >
              <ZoomIn />
            </S.Button> */}
          </>
        )}
        {isMobile && (
          <>
            <S.PageCount>
              {currentPage} / {pageCount}
            </S.PageCount>

            <S.MobileWraper>
              {/* <S.MobileColection>
                <S.Button
                  onClick={handleZoomOut}
                  aria-labelledby="Book Zoom Out Button"
                >
                  <ZoomOut />
                </S.Button>
                <S.Button
                  onClick={handleZoomIn}
                  aria-labelledby="Book Zoom In Button"
                >
                  <ZoomIn />
                </S.Button>
              </S.MobileColection> */}

              <S.MobileColection>
                <S.Button
                  onClick={handleFullscreen}
                  aria-labelledby="Book go Fullscreen"
                >
                  <Fullscreen />
                </S.Button>
                <S.Button
                  as="a"
                  href="konwiktorska_book.pdf"
                  download
                  aria-labelledby="Download pdf file with book view"
                >
                  <Download />
                </S.Button>
              </S.MobileColection>
            </S.MobileWraper>
          </>
        )}
      </S.Menu>

      <S.ArrowButton onClick={handleNextPage} disabled={isZoomedIn}>
        <SmallArrowRight />
      </S.ArrowButton>
    </S.OptionsWrapper>
  )
}
