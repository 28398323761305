import styled from 'styled-components'
import media from 'styles/media'

import { ReactComponent as Logo } from '../../assets/icons/logo.svg'

export const StyledLogo = styled(Logo)`
  max-height: 100%;

  margin: 0px auto;

  ${media.lg.max} {
    max-width: 157px;

    position: absolute;
  }
`

export const ButtonWraper = styled.div`
  width: 100%;
  max-width: 320px;
`

export const Container = styled.div`
  width: 100%;
  height: 80px;
  max-width: 1376px;

  margin-top: 20px;
  padding: 0px 40px;

  display: flex;
  flex-direction: row;
  align-items: center;

  ${media.lg.max} {
    display: none;
  }
`

export const MobileContainer = styled.div`
  width: 100%;
  height: 112px;
  background-color: white;

  display: none;

  ${media.lg.max} {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const TextContainer = styled.div`
  max-width: 320px;

  display: flex;
  flex-direction: column;
  gap: 5px;
`

export const Text1 = styled.p`
  color: #373737;
  font-family: 'IvyMode-Regular', Ivy Mode;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
`

export const Text2 = styled.p`
  color: #aa8c72;
  font-family: 'IvyMode-Regular', Ivy Mode;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

export const Button = styled.a`
  width: fit-content;

  padding: 12px 16px;
  background: #aa8c72;

  margin-left: auto;

  display: flex;
  align-items: center;

  border: none;

  color: #fff;
  font-family: 'IvyMode-Regular', Ivy Mode;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  text-transform: uppercase;
  text-decoration: none;
`
