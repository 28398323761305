import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import * as S from '.'

export const Sidemenu = ({
  isZoomedIn,
  handlePageJump,
  handleSidemenu,
}: S.SidemenuProps) => {
  const data: S.SidemenuData = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          sourceInstanceName: { eq: "assets" }
          relativeDirectory: { eq: "pdfImages/book1" }
        }
      ) {
        nodes {
          name
          publicURL
        }
      }
    }
  `)

  const images = data.allFile.nodes
    .map((node) => ({
      image: {
        src: node.publicURL,
        alt: node.name,
      },
    }))
    .slice()
    .sort((a, b) => {
      const altA = parseInt(a.image.alt.slice(6))
      const altB = parseInt(b.image.alt.slice(6))

      return altA - altB
    })

  return (
    <S.SideMenuContainer>
      <S.CloseMenu>
        <S.CloseText onClick={() => handleSidemenu()}>x</S.CloseText>
      </S.CloseMenu>

      {images.map((item, index) => {
        return (
          <button
            key={index}
            onClick={() => handlePageJump(index)}
            disabled={isZoomedIn}
          >
            <img src={item.image.src} alt={item.image.alt} />
          </button>
        )
      })}
    </S.SideMenuContainer>
  )
}
