import styled from 'styled-components'
import media from 'styles/media'

import { LazyImage } from 'components/LazyImage'
import { IGatsbyImageData } from 'gatsby-plugin-image'

export { HomePage as default } from './HomePage'

export interface ImageData {
  src: string
  alt: string
}
export interface Pin {
  position: number[]
  label: string
}
export interface PageProps {
  currentPage?: number
  pagenumber: number
  pagewidth: number
  pageheight: number
  data: {
    image: {
      src: IGatsbyImageData
      alt: string
    }
  }
}
export interface FileNode {
  relativePath: string
  name: string
  extension: string
  publicURL: string
}

export interface Data {
  allFile: {
    nodes: {
      relativePath: string
      name: string
      extension: string
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData
      }
    }[]
  }
  mobileBackground: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData
    }
  }
}

export const Img = styled(LazyImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;

  background-color: white;

  user-select: none;
  pointer-events: none;

  & img {
    background-color: white;
  }
`

export const PageContainer = styled.div<{
  pagewidth: number
  pageheight: number
}>`
  position: relative;
  width: 100%;
  height: 100%;

  max-width: ${({ pagewidth }) => `${pagewidth}px`};
  max-height: ${({ pageheight }) => `${pageheight}px`};

  background-color: white;

  ${media.lg.max} {
    max-width: none;
    max-height: none;
  }
`

export const StyledMobileBackground = styled(LazyImage)`
  display: none !important;
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;

  background-color: #755d48;

  ${media.lg.max} {
    display: initial !important;
  }
`

export const Footer = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: auto;
  padding: 20px;

  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.2px;
`

export const Button = styled.a`
  cursor: pointer;
  max-width: 270px;

  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background: #aa8c72;
  border: none;

  color: #fff;
  font-family: 'IvyMode-Regular', Ivy Mode;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  text-transform: uppercase;
`

export const MobileContainer = styled.div`
  display: none;

  ${media.lg.max} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;

    padding: 0px 16px;
    padding-bottom: 60px;
  }
`

export const Text1 = styled.p`
  color: #373737;
  text-align: center;
  font-family: 'IvyMode-Regular', Ivy Mode;
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
`

export const Text2 = styled.p`
  color: #aa8c72;
  text-align: center;
  font-family: 'IvyMode-Regular', Ivy Mode;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
`

export const CatalogWraper = styled.div`
  width: 100%;
  height: fit-content;
  max-width: 1456px;

  padding: 0px 80px;
  margin: auto 0px;

  ${media.lg.max} {
    margin: 0px;
    padding: 80px;
    margin-bottom: 80px;

    position: relative;
  }
  ${media.sm.max} {
    padding: 80px 0px;
  }
`

export const Wraper = styled.div`
  height: 100%;
  min-height: 100vh;

  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  isolation: auto;

  overflow: hidden;
`

export const StyledBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;

  background-image: url('background.webp');
  background-color: #e8e5e0;

  background-size: cover;
`

export const MobileWraper = styled.div`
  display: none;

  ${media.lg.max} {
    display: initial;
  }
`
